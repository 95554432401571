import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import * as commonModuleDe from '../../../../libs/translation/i18n/de.json';
import * as genericDe from '../assets/i18n/de.json';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../../../libs/config/services';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonComponentsModule } from '../../../../libs/common/components/common-components.module';
import { MeasurementModule } from '../../../../libs/measurement/measurement.module';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { SplashScreenStateService } from '../../../../libs/common/services/splash-screen-state.service';
import { LoggingModule } from '../../../../libs/logging/logging.module';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from '../../../../libs/config/services/global-error-handler';
import { HttpCancelService } from '../../../../libs/common/services/http-cancel.service';
import { ApiService } from '../../../../libs/api';
import { AuthModule } from '../../../../libs/auth/auth-module';
import { environment } from '../environments/environment';
import { TranslationModule } from '../../../../libs/translation/translation.module';
import { AuthInterceptor } from '../../../../libs/common/services/interceptor/auth.interceptor';
import { MarkdownModule } from 'ngx-markdown';
import { FullNamePipe } from '../../../../libs/user/full-name.pipe';
import { TraceInterceptor } from '../../../../libs/common/services/interceptor/trace.interceptor';

export class IonicGestureConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        const mc = new (window as any).Hammer(element);
        if (window) {
            for (const eventName in this.overrides) {
                if (eventName) {
                    mc.get(eventName).set(this.overrides[eventName]);
                }
            }
        }
        return mc;
    }
}

export class CurafidaTranslateLoader extends TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of({ ...(commonModuleDe as any).default, ...(genericDe as any).default });
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonComponentsModule,
        IonicModule.forRoot({
            platform: {
                /** The default `mobile` function returns true for devices with a touchscreen.
                 * This is not always wanted, so this function tests the User Agent instead.
                 * We only wanted smartphone device
                 **/
                mobile: (win) => {
                    return (
                        /capacitor|cordova|iphone|mobileweb|mobile/i.test(win.navigator.userAgent) &&
                        !/tablet|phablet|ipad/i.test(win.navigator.userAgent)
                    );
                },
            },
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useClass: CurafidaTranslateLoader },
        }),
        BrowserAnimationsModule,
        MeasurementModule,
        HammerModule,
        LoggingModule,
        ToastrModule.forRoot({
            maxOpened: 4,
            resetTimeoutOnDuplicate: true,
            includeTitleDuplicates: true,
            preventDuplicates: true,
            closeButton: true,
        }),
        AuthModule,
        TranslationModule,
        MarkdownModule.forRoot({ loader: HttpClient }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        ConfigService,
        InAppBrowser,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: 'environmentConfig', useFactory: () => environment },
        { provide: 'keycloakConfig', useFactory: () => environment.keycloak },
        { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TraceInterceptor,
            multi: true,
        },
        BluetoothSerial,
        HTTP,
        BluetoothLE,
        SplashScreenStateService,
        HttpCancelService,
        ApiService,
        FullNamePipe,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
