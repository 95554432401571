import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TraceInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !(
                request.url.includes('realms') ||
                request.url.includes('directus') ||
                request.url.includes('assets/extra')
            )
        ) {
            const traceId = crypto.randomUUID();
            const clone = request.clone({
                headers: request.headers.append('X-Request-Id', traceId),
            });
            return next.handle(clone);
        }
        return next.handle(request);
    }
}
