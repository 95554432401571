import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat } from '../entities/table';
import { format } from 'date-fns';
import dayjs from 'dayjs';

@Pipe({
    name: 'dateTransform',
    standalone: true,
})
export class DateTransformPipe implements PipeTransform {
    transform(value: string | Date, dateFormat: DateFormat): string {
        if (dateFormat && value !== '-') {
            if (dateFormat === DateFormat.DATE) {
                return format(new Date(value), 'dd.MM.yyyy');
            }
            if (dateFormat === DateFormat.DATE_AND_HOUR) {
                return format(new Date(value), 'dd.MM.yyyy') + ', ' + format(new Date(value), 'HH:mm') + ' Uhr';
            }
            if (dateFormat === DateFormat.DATE_AND_HOUR_2_LINES) {
                return format(new Date(value), 'dd.MM.yyyy') + '\n' + format(new Date(value), 'HH:mm') + ' Uhr';
            }
            if (dateFormat === DateFormat.HOUR) {
                return format(new Date(value), 'HH:mm') + ' Uhr';
            }
            if (dateFormat === DateFormat.VIDEO_CONFERENCE_ADMISSION) {
                const meetingStartTime = new Date(value);
                return format(meetingStartTime.setMinutes(meetingStartTime.getMinutes() - 15), 'HH:mm') + ' Uhr';
            }
            if (dateFormat === DateFormat.MONTH) {
                return dayjs(value).format('MM.YYYY');
            }
            if (dateFormat === DateFormat.YEAR) {
                return dayjs(value).format('YYYY');
            }
        }
        return value.toString();
    }
}
