// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.init-font-weight {
  font-weight: initial;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-date-input/curafida-date-input.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ","sourcesContent":[".init-font-weight {\n    font-weight: initial;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
