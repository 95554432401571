import { Pipe, PipeTransform } from '@angular/core';
import { PageConfig } from '../entities';
import { UserRoles } from '../../auth/entities/user';
import { CurafidaFeatureEnum } from '../entities/base-page-config';
import { FeatureConfigs } from '../../common/entities/curafida-frontend-configuration';

@Pipe({
    name: 'MenuItemsFilter',
    standalone: true,
    pure: true,
})
export class MenuItemsFilterPipe implements PipeTransform {
    transform(menuItems: PageConfig[], userRoles: UserRoles[] | string[], globalConfiguration: FeatureConfigs) {
        // Do not show registration button if there is no registerUrl in config
        if (menuItems && !globalConfiguration?.user?.registerUrl) {
            menuItems = menuItems.filter((x) => x.pageId !== 'REGISTER');
        }

        if (!userRoles) {
            return menuItems;
        }

        if (!menuItems) {
            return menuItems;
        }
        if (userRoles.length === 0) {
            return menuItems.filter((menuItem) => {
                const hasSubPage = menuItem.childPageConfigs?.length > 0;
                if (hasSubPage) {
                    return menuItem.role.length === 0;
                } else {
                    if (
                        globalConfiguration[menuItem?.module]?.enabled ||
                        globalConfiguration[menuItem?.module]?.enabled === undefined ||
                        menuItem.module === CurafidaFeatureEnum.common
                    ) {
                        return menuItem.role.length === 0;
                    }
                }
            });
        }

        return menuItems.filter((menuItem) => {
            const hasSubPage = menuItem.childPageConfigs?.length > 0;
            if (hasSubPage) {
                return menuItem.role.find((role) => userRoles.includes(role as UserRoles));
            } else {
                if (globalConfiguration[menuItem?.module]?.enabled || menuItem.module === CurafidaFeatureEnum.common) {
                    return menuItem.role.find((role) => userRoles.includes(role as UserRoles));
                } else {
                    return false;
                }
            }
        });
    }
}
