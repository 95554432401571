import { Component, Input, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { StyleService } from '../../services/style/style.service';
import { TimeLineItemAdapterComponent, TimelineState } from '../curafida-timeline/timeline.entity';
import { Observable } from 'rxjs';
import { TimelineItemIconConfigPipe } from '../curafida-timeline/curafida-timeline-item-icon-config.pipe';
import { TimelineLineDefinitionComponent } from '../curafida-timeline/timeline-item-definition/timeline-line-definition.component';

export interface ActionlineEntity {
    text: string;
    state: TimelineState;
}

@Component({
    selector: 'curafida-action-line',
    templateUrl: './curafida-action-line.component.html',
    styleUrls: ['./curafida-action-line.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgIf,
        AsyncPipe,
        TimelineItemIconConfigPipe,
        TimelineLineDefinitionComponent,
        NgClass,
        NgForOf,
    ],
})
export class CurafidaActionLineComponent {
    @Input() items: Observable<ActionlineEntity[]>;
    @Input() adapter: Type<TimeLineItemAdapterComponent<ActionlineEntity>>;
    isMobile: boolean;

    constructor(public styleService: StyleService) {
        this.isMobile = this.styleService.isMobile$;
    }
}
