import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { QuestionnaireType } from './questionnaire-type';
import { Questionnaire } from 'fhir/r4';

export enum FhirQuestionnaireReference {
    ItemControlExtensionURL = 'https://www.hl7.org/fhir/R4/StructureDefinition/questionnaire-itemControl',
    ItemControlCodeSystemURL = 'https://www.hl7.org/fhir/R4/questionnaire-item-control',
    QuestionnaireUnitExtensionURL = 'https://www.hl7.org/fhir/R4/StructureDefinition/questionnaire-unit',
    QuestionnaireOrdinalValueExtensionUrl = 'https://www.hl7.org/fhir/R4/StructureDefinition/ordinalValue',
}

export class CurafidaQuestionnaireDto {
    @Column({ type: 'simple-json' })
    fhirQuestionnaire: Questionnaire;

    @Column()
    curafidaQuestionnaireType: QuestionnaireType = QuestionnaireType.DEFAULT;

    @Column()
    active: boolean;
}

@Entity('CurafidaQuestionnaire')
export class CurafidaQuestionnaire extends CurafidaQuestionnaireDto {
    @PrimaryGeneratedColumn('uuid')
    uuid: string;

    @Column()
    created_at: Date;

    @Column()
    updated_at: Date;
}

export class QuestionnaireResponseSummary {
    scoreEntries: ScoreEntry[] = [];
    totalQuestionCount = 0;
    answeredQuestionCount = 0;
    totalScore: number;
}

export class ScoreEntry {
    id: string;
    questionText: string;
    answerText: string;
    score: number;
}
