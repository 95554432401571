import { SegmentType } from '../../../entities/segment.type';

export enum CurafidaSearchAndButtonEventAction {
    UPDATE_FILTER,
    UPDATE_SEGMENT,
}

export interface CurafidaSearchAndButtonEvent {
    action: CurafidaSearchAndButtonEventAction;
    payload: CurafidaSearchAndButtonPayload;
}

export type CurafidaSearchAndButtonPayload = CurafidaSearchAndButtonUpdateFilter | CurafidaSearchAndButtonUpdateSegment;

export interface CurafidaSearchAndButtonUpdateFilter {
    searchTerm?: string;
}

export interface CurafidaSearchAndButtonUpdateSegment {
    segment: SegmentType;
}
