import { ExerciseSubType, ExerciseType } from '../../../therapy/entities/exerciseSession';
import { SortOrder } from '../../entities/paginated-response';
import { EventEmitter } from '@angular/core';

export enum TimelineType {
    SINGLE_COURSE = 'SINGLE_COURSE',
    GROUP_COURSE = 'GROUP_COURSE',
    EXAMINATION = 'EXAMINATION',
    ANNOUNCEMENT = 'ANNOUNCEMENT',
    USER_CHANGED_EVENT = 'USER_CHANGED_EVENT',
    PATIENT_REPORT_CREATED = 'PATIENT_REPORT_CREATED',
    CREATE_CONSULTATION_PDF_REPORT = 'CREATE_CONSULTATION_PDF_REPORT',
    CREATE_ORDER_PDF_REPORT = 'CREATE_ORDER_PDF_REPORT',

    USER_CREATED = 'USER_CREATED',
    USER_UPDATED = 'USER_UPDATED',
    USER_DELETED = 'USER_DELETED',
    ROLES_CHANGED = 'ROLES_CHANGED',
    SUPERVISORS_CHANGED = 'SUPERVISORS_CHANGED',
    CAREGIVERS_CHANGED = 'CAREGIVERS_CHANGED',
    TAG_ASSIGNED = 'TAG_ASSIGNED',
    TAG_UNASSIGNED = 'TAG_UNASSIGNED',
    USER_CUSTOM_PROP_CHANGED = 'USER_CUSTOM_PROP_CHANGED',
    NOTE_CREATED = 'NOTE_CREATED',
    NOTE_CHANGED = 'NOTE_CHANGED',
    NOTE_DELETED = 'NOTE_DELETED',

    // exercise subtype
    INFORMATION = 'INFORMATION',

    // Solange es noch den alten ExerciseType "LEARNING" gibt und Tasks mit dem Subtyp LEARNING
    // existieren müssen diese unterschieden werden
    LEARNING_LEGACY = 'LEARNING_LEGACY',
}

export type TimelineItemTyp = ExerciseType | ExerciseSubType | TimelineType;
export const TimelineItemTyp = { ...ExerciseType, ...ExerciseSubType, ...TimelineType };

export enum TimelineState {
    SUCCESS = 'SUCCESS', // green check
    WAITING = 'WAITING', // blue without icon
    WARNING = 'WARNING', // blue exclamation mark
    FAILURE = 'FAILURE', // red close icon
    LOCKED = 'LOCKED', // red exclamation mark
}

export class TimelineOutputParams {
    sortOrder?: SortOrder;
    overlapRangeStart?: string;
    overlapRangeEnd?: string;
    limit?: number = 10;
    offset?: number = 0;
}

export interface TimeLineItemAdapterComponent<T> {
    item: T;
    items: T[];
    actionSelected?: EventEmitter<T>;
}
