import { TableItem, TableUpdateValue } from '../../../entities/table';

export enum CurafidaTableEventAction {
    CLICK,
    UPDATE_TABLE,
    REORDER_TABLE,
    UPDATE_SORT_ORDER,
}

export interface CurafidaTableEvent {
    action: CurafidaTableEventAction;
    payload: TableItem | TableUpdateValue;
}
