export enum QuestionnaireType {
    DEFAULT = 'DEFAULT',
    MEASUREMENT = 'MEASUREMENT',
    TASK = 'TASK',
    CONSULTATION = 'CONSULTATION',
    MEDICAL_HISTORY = 'MEDICAL_HISTORY',
    SCREENING = 'SCREENING',
    ORDER = 'ORDER',
}

export enum ChoiceType {
    NONE = 'NONE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    SINGLE_CHOICE = 'SINGLE_CHOICE',
}
