import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'curafida-checkbox-input',
    templateUrl: './curafida-checkbox-input.component.html',
    styleUrls: ['./curafida-checkbox-input.component.scss'],
})
export class CurafidaCheckboxInputComponent extends CurafidaInputComponent {
    @Input()
    suffix: string;

    @Input()
    isChecked = false;

    @Output()
    ionChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
    }

    onIonChange(event: any): void {
        this.ionChange.emit(event);
    }
}
