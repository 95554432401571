// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-range::part(pin) {
  color: #fff;
  transform: scale(1);
  top: -25px;
}

.slider-input-explanation {
  font-weight: initial;
}

ion-range.hidden-pin::part(pin) {
  display: none;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-slider-input/curafida-slider-input.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,UAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":["ion-range::part(pin) {\n    color: #fff;\n    transform: scale(1);\n    top: -25px;\n}\n\n.slider-input-explanation {\n    font-weight: initial;\n}\n\nion-range.hidden-pin::part(pin) {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
