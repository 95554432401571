import { Component, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { CurafidaTextAreaFullHeightComponent } from '../curafida-text-area-full-height/curafida-text-area-full-height.component';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Content } from '../../../../therapy/entities/content';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'curafida-text-area',
    templateUrl: './curafida-text-area.component.html',
    styleUrls: ['./curafida-text-area.component.scss'],
})
export class CurafidaTextAreaComponent extends CurafidaInputComponent implements OnInit {
    @Input() maxlength: number;
    @Input() autoGrow = true;
    @Input() minHeight = false;
    @Input() isMobile: boolean;
    @Input() hasExpandButton = false;
    @Input() textAreaContents: Content[];
    @Input() explanation: string;
    @Input() informationTooltip: string;
    @Input() floatingLabelMobile = true;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public element: ElementRef,
        private modalCtrl: ModalController,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    async expandTextArea() {
        const modal = await this.modalCtrl.create({
            component: CurafidaTextAreaFullHeightComponent,
            cssClass: 'full-height-modal',
            backdropDismiss: false,
            componentProps: { text: this.formGroup.controls[this.formControlName].value },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.formGroup.controls[this.formControlName].setValue(data);
        }
    }
}
