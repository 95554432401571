import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Content } from '../../../therapy/entities/content';
import { ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: `
        <div
            style="width: 98%"
            class="flex-container-row justify-content-flex-start">
            <div style="height: auto; width: 100%; padding-inline-start: 0; --margin:0">
                <img
                    *ngIf="data.url && data?.mimeType && Content.isImageMimeType(data.mimeType)"
                    [src]="data.url"
                    [alt]="data?.description"
                    style="height:100%; width: auto; border-radius: 5px" />
                <ion-icon
                    *ngIf="data?.mimeType && Content.isVideoMimeType(data.mimeType)"
                    color="primary"
                    name="play-circle-outline"
                    style="font-size: 3rem">
                </ion-icon>
                <div
                    *ngIf="!data.mimeType || !data.url"
                    style="width:100%">
                    -
                </div>
                <img
                    *ngIf="data.mimeType === 'application/pdf'"
                    src="assets/pdf/pdf_logo.png"
                    [alt]="data.description"
                    style="height:60px; width: auto;" />
            </div>
        </div>
    `,
    standalone: true,
    imports: [NgIf, IonicModule],
})
export class MediaContentAdapterComponent implements ItemAdapterComponent {
    @Input() data: Content;
    @Input() itemSetting: TableItem;
    Content = Content;
}
