import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ConfigService } from '../../../config/services';

@Injectable({ providedIn: 'root' })
export class RegistrationPageGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly configService: ConfigService,
    ) {}

    async canActivate(): Promise<UrlTree | boolean> {
        if (this.configService.config?.features?.user?.registerUrl) {
            return true;
        }
        // Entering registration page is not allowed -> go to login page
        return this.router.parseUrl('/login');
    }
}
