import { Component, ElementRef, EventEmitter, Input, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { ModalController } from '@ionic/angular';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ExerciseContentsService } from '../../../../therapy/services/exercise-contents';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../entities/white-list-mime-type';
import { IonicColor } from '../../../entities/toast/ionic-color';
import { ToastService } from '../../../services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'curafida-upload-input',
    templateUrl: './curafida-upload-input.component.html',
    styleUrls: ['./curafida-upload-input.component.scss'],
})
export class CurafidaUploadInputComponent extends CurafidaInputComponent {
    protected readonly log: Logger;

    @Input()
    isMultiple = false;
    @Input()
    mimeTypeAccepted = UPLOAD_MIME_TYPE_WHITELIST.join();
    @Output()
    uploadFile: EventEmitter<FileItem> = new EventEmitter<FileItem>();
    uploader: FileUploader;
    @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;

    constructor(
        protected exerciseContentService: ExerciseContentsService,
        protected modalCtrl: ModalController,
        protected toastService: ToastService,
        private loggingService: LoggingService,
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    openUploadWindows() {
        this.uploader = new FileUploader({
            url: '',
            allowedMimeType: this.mimeTypeAccepted.split(','),
            queueLimit: this.isMultiple ? Number.MAX_VALUE : 1,
            // maxFileSize: 20 * 1024 * 1024
        });
        this.uploader.clearQueue();
        this.fileUploader.nativeElement.click();
        this.uploader.onAfterAddingAll = async (files) => {
            for (const file of files) {
                await this.uploadFile.emit(file);
            }
            this.fileUploader.nativeElement.value = '';
            this.uploader.clearQueue();
        };
        this.uploader.onWhenAddingFileFailed = async (item, filter) => {
            switch (filter.name) {
                // The size filter first has to be enabled in the FileUploader options
                case 'fileSize':
                    this.toastService.showToast(
                        `Die Datei ${item.name} ist zu groß. Maximale Dateigröße 20 MB.`,
                        IonicColor.danger,
                    );
                    break;
                case 'mimeType':
                    this.toastService.showToast(
                        `Das Format der Datei ${item.name} wird nicht unterstützt.`,
                        IonicColor.danger,
                    );
                    break;
                // The queue limit first has to be enabled in the FileUploader options
                case 'queueLimit':
                    this.toastService.showToast(
                        `Datei ${item.name} wurde nicht hochgeladen, es kann nur eine Datei gleichzeitig hochgeladen werden`,
                        IonicColor.danger,
                    );
                    break;
                default:
                    this.log.error('Error in openUploadWindows', `Unknown error (filter is ${filter.name})`);
                    this.toastService.showToast(
                        `Beim Hochladen der Datei ${item.name} ist ein Fehler aufgetreten.`,
                        IonicColor.danger,
                    );
                    break;
            }
        };
    }
}
