import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform, ToggleCustomEvent } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { UserRoles } from '../../../auth/entities/user';
import { CurafidaAuthService } from '../../../auth/services';
import { AppConfiguration } from '../../../config/entities';
import { BuildType } from '../../../config/entities/build-type';
import { ConfigService } from '../../../config/services';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { RoutingSegment } from '../../entities/routing-segment';
import { BrowserNavigationService } from '../../services/browser-navigation/browser-navigation.service';
import { MenuService } from '../../services/menu.service';
import { SplashScreenStateService } from '../../services/splash-screen-state.service';
import { VersionService } from '../../services/version.service';
import { Capacitor } from '@capacitor/core';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-curafida-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
    isDebuggingAttributeEnabled = false;
    appConfig: AppConfiguration;
    routerActionSubscription: Subscription;
    roles: UserRoles[] | string[] = [];
    iosPlatform = false;
    frontendVersionString: string;
    backendVersionString: string;
    BuildType = BuildType;
    readonly basicUid = '426245708CURHN';
    readonly androidUid = '42624570800028';
    readonly iosUid = '42624570800035';
    readonly webUid = '42624570800011';
    @Input()
    contentID: string;
    isUserAuthenticated: boolean;
    protected readonly MenuService = MenuService;
    private log: Logger;

    constructor(
        public readonly router: Router,
        public readonly configService: ConfigService,
        private readonly authService: CurafidaAuthService,
        private readonly versionService: VersionService,
        protected readonly platform: Platform,
        private splashScreenStateService: SplashScreenStateService,
        private loggingService: LoggingService,
        private browserNavigation: BrowserNavigationService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.iosPlatform = this.platform.is('ios');
        this.appConfig = this.configService.config;

        // TODO: prüfen und verbessern!
        await this.initSideMenu();
        this.routerActionSubscription = this.router.events.subscribe(async (evt) => {
            if (evt instanceof NavigationEnd) {
                await this.initSideMenu();
            }
        });
    }

    async initSideMenu() {
        this.isUserAuthenticated = await this.authService.isAuthenticated(false, false);
        this.authService
            .getRoles()
            .then((roles) => {
                this.roles = roles;
            })
            .catch((err) => {
                this.log.error(err);
            });
        this.authService
            .getDebuggingAttribute()
            .then((debuggingEnabled) => {
                this.isDebuggingAttributeEnabled = debuggingEnabled;
            })
            .catch((err) => {
                this.log.error(err);
            });
        await this.versionService.setBackendVersion();
        this.versionService.getFrontendVersionString().then((versionString: string) => {
            this.frontendVersionString = versionString;
        });
        this.versionService.getBackendVersionString().then((versionString: string) => {
            this.backendVersionString = versionString;
        });
    }

    async logout() {
        this.splashScreenStateService.start();
        await this.authService.logout();
        await this.router.navigate(['login']);
    }

    async openPage(url: string) {
        if (url) {
            await this.router.navigate([url]);
        }
    }

    switchLogging(ev: ToggleCustomEvent) {
        this.log.info('Enabling in-app logs');
        this.log.info('Using log level', this.loggingService.getGlobalLogLevelStr());
        this.loggingService.publishSwitchLogViewState(ev.detail.checked);
    }

    // Check if at least one of the categories is in the URL, for menu highlighting
    menuItemActive(categories: RoutingSegment[]) {
        for (const category of categories) {
            if (this.router.url.includes(category)) {
                return true;
            }
        }
        return false;
    }

    async login() {
        await this.authService.login();
    }

    async onMenuOpen() {
        await this.initSideMenu();
    }

    openUserManual() {
        let url = 'https://handbuch.ztm.de/curafida-ueberblick';
        if (this.appConfig.appDisplayName === 'Mia') {
            url = 'https://handbuch.ztm.de/mia/mia-handbuch';
        }
        this.browserNavigation.openTargetBlank(url);
    }

    protected readonly Capacitor = Capacitor;
}
