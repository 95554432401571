import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/*
 * General validator for any input that doesn't require more precise pattern validation.
 * The '\u0000-\u00FF' regex range only allows Unicode groups Basic Latin and Latin-1 Supplement.
 */
export const defaultInputValidator: ValidatorFn = Validators.compose([Validators.pattern(/^[\u0000-\u00FF]*$/)]);
/*
 * General validator for any input that require text with lists and list symbols
 * U+2022: • (Bullet point)
 * U+2023: ‣ (One dot leader)
 * U+25E6: ◦ (White bullet)
 * U+2043: ⁃ (Hyphen bullet)
 * U+2219: ⋅ (Bullet operator)
 * U+25AA: ▪ (Black small square)
 * U+25AB: ▫ (White small square)
 * U+25CF: ● (Black circle)
 * U+25CB: ○ (White circle)
 * U+20AC: € (Euro-Symbol)
 *
 */
export const defaultInputWithListSymbolValidator: ValidatorFn = Validators.compose([
    Validators.pattern(/^[\u0000-\u00FF\u2022\u2023\u25E6\u2043\u2219\u25AA\u25AB\u25CF\u25CB\u20AC]*$/),
]);
/*
 * General validator for any input that doesn't require more precise pattern validation.
 * The '\u0000-\u00FF' regex range only allows Unicode groups Basic Latin and Latin-1 Supplement.
 */
export const defaultRequiredInputValidator: ValidatorFn = Validators.compose([
    Validators.pattern(/^[\u0000-\u00FF]*$/),
]);
/*
 * Validator that only allows numbers, with or without decimals, minimum is 0 because it does not include positive or negative signs.
 * Decimal separators can be either a normal period (decimal point) or a comma, both are allowed.
 */
export const decimalNumberValidator: ValidatorFn = Validators.compose([
    Validators.pattern(/^([0-9]+[.|,]?[0-9]*|[.|,][0-9]+)$/),
]);
/*
 * This validator ensures that the input doesn't start or end with an empty character (like a whitespace).
 */
export const noWhitespaceValidator: ValidatorFn = Validators.compose([Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)]);

export const normValueFormValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const warningRangeLow = control.get('warningRangeLow').value;
    const normalRangeLow = control.get('normalRangeLow').value;
    const targetValue = control.get('targetValue').value;
    const normalRangeHigh = control.get('normalRangeHigh').value;
    const warningRangeHigh = control.get('warningRangeHigh').value;

    const values = [];
    if (warningRangeLow && warningRangeLow !== '') values.push(Number(warningRangeLow.replace(',', '.')));
    if (normalRangeLow && normalRangeLow !== '') values.push(Number(normalRangeLow.replace(',', '.')));
    if (targetValue && targetValue !== '') values.push(Number(targetValue.replace(',', '.')));
    if (normalRangeHigh && normalRangeHigh !== '') values.push(Number(normalRangeHigh.replace(',', '.')));
    if (warningRangeHigh && warningRangeHigh !== '') values.push(Number(warningRangeHigh.replace(',', '.')));

    let invalidNormValueOrder = null;
    for (const value of values) {
        if (!(values.indexOf(value) === values.length - 1) && value >= values[values.indexOf(value) + 1]) {
            invalidNormValueOrder = { invalidOrder: true };
        }
    }

    return invalidNormValueOrder;
};

// Check if an end date is after a start date
export const isEndDateAfterStartDate: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const startControl = control.get('start');
    const endControl = control.get('end');

    // Safety check
    if (!startControl || !endControl) {
        return null;
    }

    // Extract the values from the controls and initialize a null return value for valid cases
    const startValue = startControl.value;
    const endValue = endControl.value;
    let invalidTimePeriod = null;

    // Fill the return value for the invalid cases
    if (startValue && endValue && Number(startValue) > Number(endValue)) {
        invalidTimePeriod = { invalidPeriod: true };
    }

    return invalidTimePeriod;
};

/**
 * Validate that the form control has a valid EUR price value.
 *
 * The following decimal formats are allowed:    12    12.    12.34    .34
 * The decimal point can be specified by dot or comma.
 */
export const isEurPrice: ValidatorFn = Validators.pattern(/^(?:[.,]\d{1,2}|\d+(?:[.,]\d{0,2})?)$/);

export const numberValidator: ValidatorFn = Validators.compose([Validators.pattern('^[0-9]*$')]);

export const isEMail: ValidatorFn = Validators.pattern(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
);

const requiredValidations = [
    defaultInputValidator,
    noWhitespaceValidator,
    Validators.minLength(1),
    Validators.required,
];

export const requiredValidators: Validators = Validators.compose(requiredValidations);

export const requiredUserNameValidators: Validators = Validators.compose([
    ...requiredValidations,
    Validators.pattern(/^([A-Za-zÀ-ÿ]|[0-9]|_|-|\.)+(\s([A-Za-zÀ-ÿ]|[0-9]|_|-|\.)+)*$/i),
    Validators.pattern(/^.{2,50}$/),
]);

export const phoneValidators: Validators = Validators.compose([
    defaultInputValidator,
    noWhitespaceValidator,
    Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i),
]);

/**
 * Returns a validator function for user custom property repeat validation.
 * If the repeat validation is enabled in the user custom property schema
 * and the associated checkbox is checked, then the user has to enter the input
 * twice. Both inputs must be the same for this validator to pass.
 */
export function repeatValidationValidator(
    customPropertyFormControlName: string,
    validationRepeatCheckboxFormControlName: string,
    validationRepeatInputFormControlName: string,
): ValidatorFn {
    return (controlGroup: AbstractControl): ValidationErrors | null => {
        const customPropControl = controlGroup.get(customPropertyFormControlName);
        const repeatCheckboxControl = controlGroup.get(validationRepeatCheckboxFormControlName);
        const repeatInputControl = controlGroup.get(validationRepeatInputFormControlName);
        if (!repeatCheckboxControl.getRawValue()) {
            // Checkbox is not checked. Nothing to validate
            repeatInputControl.setErrors(null);
            return null;
        }
        if (customPropControl.getRawValue() === repeatInputControl.getRawValue()) {
            // The repeat input matches the custom prop value
            repeatInputControl.setErrors(null);
            return null;
        }
        const error = { repeatValidation: true };
        repeatInputControl.setErrors(error);
        return error;
    };
}
