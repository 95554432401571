import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FileItem, FileUploader, FileUploadModule } from 'ng2-file-upload';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { CurafidaTableComponent } from '../../../../table/components/curafida-table/curafida-table.component';
import { LoadingProcess, LoadingStates } from '../../../../table/components/curafida-table/loading.process';
import { MediaContentAdapterComponent } from '../../../../table/components/table-adapter/media-content-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
} from '../../../../table/entities/table';
import { Content } from '../../../../therapy/entities/content';
import { PaginatedResponse } from '../../../entities/paginated-response';
import { IonicColor } from '../../../entities/toast/ionic-color';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../entities/white-list-mime-type';
import { ToastService } from '../../../services/toast-service/toast-service.service';
import { CurafidaContentInputForm } from './curafida-content-input.form';

class OrderedContent extends Content {
    order: number;
    fileItem?: FileItem;

    constructor(content: Content, order: number, fileItem?: FileItem) {
        super();
        Object.assign(this, content);
        this.order = order;
        if (!fileItem) return;
        /* Set the filename to display on the table */
        this.origFileName = fileItem.file.name;
        this.fileItem = fileItem;
        /* Set the attributes needed for the thumbnail to be shown */
        const fileURL = URL.createObjectURL(fileItem._file);
        this.url = fileURL;
        this.mimeType = fileItem.file.type;
    }
}

@Component({
    selector: 'curafida-content-input',
    templateUrl: './curafida-content-input.component.html',
    styleUrls: ['./curafida-content-input.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, IonicModule, CurafidaTableComponent, TranslateModule, FileUploadModule],
})
export class CurafidaContentInputComponent implements OnInit {
    @Input()
    title = 'MEDIA.PLURAL';
    @Input()
    formGroup: FormGroup;
    @Input()
    isMultiple = false;
    @Input()
    informationTooltip: string;
    @Input()
    mimeTypeAccepted = UPLOAD_MIME_TYPE_WHITELIST.join();
    @Input()
    fileUploadForm: CurafidaContentInputForm;
    @Input()
    isMobile = false;
    @Input()
    isNew: boolean = false;
    @Input()
    isNewLabel: string;
    @Input()
    emptyListLabel: string;
    @Input()
    hasReorder: boolean;
    @Output()
    removeOneContent: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    openOneContent: EventEmitter<Content> = new EventEmitter<Content>();
    @Output()
    addOneContent: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    addMultipleContents: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output()
    reorderContent: EventEmitter<void> = new EventEmitter<void>();

    isLoading: LoadingStates;
    listTableConfig: TableConfig<OrderedContent[]>;
    private _isEditEnabled: boolean;
    private _contents: Content[];

    uploader: FileUploader;
    @ViewChild('fileUploader', { static: true })
    fileUploader: ElementRef;
    private readonly log: Logger;

    @Input()
    set isEditEnabled(value: boolean) {
        const isInitialization = this._isEditEnabled === undefined;
        this._isEditEnabled = value ?? false;
        /* Some inputs might not be defined yet on initialization, so run this on the appropriate lifecycle hook */
        if (isInitialization) return;
        this.toggleEditableState();
    }

    get isEditEnabled(): boolean {
        return this._isEditEnabled;
    }

    @Input()
    set contents(value: Content[]) {
        const isInitialization = this._contents === undefined;
        this._contents = value ?? [];
        /* Some inputs might not be defined yet on initialization, so run this on the appropriate lifecycle hook */
        if (isInitialization) return;
        this.loadTableListItems();
    }

    get contents(): Content[] {
        return this._contents;
    }

    constructor(
        protected modalCtrl: ModalController,
        protected toastService: ToastService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isLoading = LoadingProcess.initLoadingProcess();
        this.initTableConfig();
    }

    private initTableConfig() {
        this.listTableConfig = new TableConfig<OrderedContent[]>();
        this.listTableConfig.isReorderDisable = true;
        this.listTableConfig.emptyListLabel = this.emptyListLabel ?? 'CONTENT.ANY_THUMBNAIL';
        this.listTableConfig.isOpenDetailEnable = true;
        this.listTableConfig.hideHeader = true;
        this.listTableConfig.itemSettings = [
            { id: 'reorder', prop: '', type: ItemType.NUMBERING, width: '10%', columnPosition: 0 },
            {
                id: 'thumbnail',
                prop: 'url',
                type: ItemType.ADAPTER,
                adapter: MediaContentAdapterComponent,
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '20%',
                disabled: true,
                columnPosition: 1,
            },
            {
                id: 'origFileName',
                prop: 'origFileName',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '55%',
                columnPosition: 2,
            },
            {
                id: 'action_delete',
                prop: 'id',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'trash',
                actionType: ActionType.DELETE,
                width: '15%',
                color: 'danger',
                columnPosition: 3,
                disabled: true,
            },
        ];
    }

    ngOnInit(): void {
        if (!this.hasReorder) {
            const index = this.listTableConfig.itemSettings.findIndex((column) => column.id === 'reorder');
            this.listTableConfig.itemSettings.splice(index, 1);
            this.listTableConfig.itemSettings.find((column) => column.id === 'origFileName').width = '65%';
        }
        this.loadTableListItems();
        this.toggleEditableState();
    }

    async loadTableListItems() {
        const orderedContents = this._contents.map((content, index) => new OrderedContent(content, index));
        this.listTableConfig.list = PaginatedResponse.init(orderedContents);
        this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
    }

    openUploadWindows(): void {
        this.uploader = new FileUploader({
            url: '',
            allowedMimeType: this.mimeTypeAccepted.split(','),
            queueLimit: this.isMultiple ? Number.MAX_VALUE : 1,
            // maxFileSize: 20 * 1024 * 1024
        });
        this.uploader.clearQueue();
        this.fileUploader.nativeElement.click();
        this.uploader.onAfterAddingAll = async (files: FileItem[]) => {
            for (const file of files) {
                this.fileUploadForm.push(file);
                this.addOneContent.emit(file.file?.name);
            }
            this.addContentsToTable(files);
            this.formGroup.markAsDirty();
            this.fileUploader.nativeElement.value = '';
            this.uploader.clearQueue();
            this.addMultipleContents.emit(files.map((file) => file.file.name));
        };
        this.uploader.onWhenAddingFileFailed = async (item, filter) => {
            switch (filter.name) {
                // The size filter first has to be enabled in the FileUploader options
                case 'fileSize':
                    this.toastService.showToast(
                        `Die Datei ${item.name} ist zu groß. Maximale Dateigröße 20 MB.`,
                        IonicColor.danger,
                    );
                    break;
                case 'mimeType':
                    this.toastService.showToast(
                        `Das Format der Datei ${item.name} wird nicht unterstützt.`,
                        IonicColor.danger,
                    );
                    break;
                // The queue limit first has to be enabled in the FileUploader options
                case 'queueLimit':
                    this.toastService.showToast(
                        `Datei ${item.name} wurde nicht hochgeladen, es kann nur eine Datei gleichzeitig hochgeladen werden`,
                        IonicColor.danger,
                    );
                    break;
                default:
                    this.log.error('Error in openUploadWindows', `Unknown error (filter is ${filter.name})`);
                    this.toastService.showToast(
                        `Beim Hochladen der Datei ${item.name} ist ein Fehler aufgetreten.`,
                        IonicColor.danger,
                    );
                    break;
            }
        };
    }

    async onClickAction(actionEmitter: ActionEmitter<OrderedContent>): Promise<void> {
        if (actionEmitter.actionType === ActionType.DELETE) {
            this.removeContent(actionEmitter.item);
        }
        if (actionEmitter.actionType === ActionType.OPEN_NEW_PAGE) {
            if (
                this.isEditEnabled ||
                !this.listTableConfig.isReorderDisable ||
                !this.listTableConfig.isOpenDetailEnable
            ) {
                return;
            }
            this.openOneContent.emit(actionEmitter.item);
        }
    }

    private removeContent(content: OrderedContent) {
        /* Remove the content from the local table in the ui */
        this.listTableConfig.list.items.splice(this.listTableConfig.list.items.indexOf(content), 1);
        /* If content has a uuid adjust the original content array */
        if (content.uuid) {
            const contentToRemoveIndex = this.contents.findIndex((value) => value.uuid === content.uuid);
            this.contents.splice(contentToRemoveIndex, 1);
            this.formGroup.markAsDirty();
            /* And emit the event in case the parent component needs to react to it */
            this.removeOneContent.emit(content.uuid);
            return;
        }
        /* Otherwise the content is only loaded locally in the frontend, so remove it from upload form */
        const fileToRemove = this.fileUploadForm.files.find((file) => file === content.fileItem);
        this.fileUploadForm.remove(fileToRemove);
    }

    onReorder() {
        /* Reflect the new order on the original array */
        this.contents.sort((a, b) => {
            const itemA = this.listTableConfig.list.items.find((item) => item.uuid === a.uuid);
            const itemB = this.listTableConfig.list.items.find((item) => item.uuid === b.uuid);
            return itemA.order - itemB.order;
        });
        this.formGroup.markAsDirty();
        /* And emit the event in case the parent component needs to react to it */
        this.reorderContent.emit();
    }

    private toggleEditableState() {
        this.fileUploadForm.isDisabled = !this.isEditEnabled;
        const exerciseActionSetting = this.listTableConfig.itemSettings.find((ti) => ti.id === 'action_delete');
        exerciseActionSetting.disabled = !this.isEditEnabled;
        if (!this.hasReorder) return;
        this.listTableConfig.isReorderDisable = !this.isEditEnabled;
        const exerciseOrderSetting = this.listTableConfig.itemSettings.find((ti) => ti.id === 'reorder');
        if (this.isEditEnabled) {
            exerciseOrderSetting.type = ItemType.REORDER;
        } else {
            exerciseOrderSetting.type = ItemType.NUMBERING;
        }
    }

    private addContentsToTable(files: FileItem[]) {
        const tempContents: OrderedContent[] = [];
        files.forEach((file, index) => {
            const tempContent = new OrderedContent(
                new Content(),
                this.listTableConfig.list.items.length + index + 1,
                file,
            );
            tempContents.push(tempContent);
        });
        this.listTableConfig.list = PaginatedResponse.init([...this.listTableConfig.list.items, ...tempContents]);
    }
}
