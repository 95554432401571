import { SortBy, SortOrder } from '../../../common/entities/paginated-response';

export class AnnouncementSetting {
    readUuid: string[] = [];
}

export class UserPreferences {
    emailNotifications: boolean;
    hideWelcomeScreen: boolean;
    defaultHomePage: string;
    filterSettings?: FilterSetting;
    frontendSettings: FrontendSetting;

    constructor(
        username: string,
        emailNotifications?: boolean,
        hideWelcomeScreen?: boolean,
        defaultHomePage?: string,
        filterSettings?: FilterSetting,
        frontendSettings?: FrontendSetting,
    ) {
        this.emailNotifications = emailNotifications;
        this.hideWelcomeScreen = hideWelcomeScreen;
        this.defaultHomePage = defaultHomePage;
        this.filterSettings = filterSettings;
        this.frontendSettings = frontendSettings;
    }
}

export class FilterSetting {
    filterSettingItem: FilterSettingItem[] = [];
}

export class FilterSettingItem {
    name: string;
    sortBy: SortBy;
    sortByArray: SortBy[];
    sortOrder: SortOrder;
    filter: string;
    limit: number;
    offset: number;

    constructor(name: string) {
        this.name = name;
    }
}

export class FrontendSetting {
    announcementsSetting: AnnouncementSetting;
    lastViewWeekResume: string;
}
