import { Component, Input, OnInit } from '@angular/core';
import { MyMedaxQuestionnaireSubmission } from '../../entities/my-medax-questionnaire-submission';
import { ItemType, TableConfig } from '../../../table/entities/table';
import { UsersService } from '../../../user/services/user';
import { MyMedaxService } from '../../services/my-medax.service';
import { ConsultationService } from '../../../consultation/services/consultation.service';
import { ConsultationContent } from '../../../consultation/entities/consultation-content';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { format, parse, parseISO } from 'date-fns';
import { StyleService } from '../../../common/services/style/style.service';
import { OrderContent } from '../../../order/entities/order-content';
import { OrderService } from '../../../order/services/order.service';
import { MyMedaxFillLink } from '../../entities/my-medax-fill-link';
import { TherapyMyMedaxService } from '../../../therapy/services/therapy-my-medax';
import { TaskResource } from '../../../therapy/components/task/task.resource';
import { BrowserNavigationService } from '../../../common/services/browser-navigation/browser-navigation.service';
import { IconAdapterComponent } from '../../../table/components/table-adapter/icon-adapter.component';
import { QuestionAndAnswer } from '../../entities/question-and.answer';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { MyMedaxRedirection } from '../../my-medax.redirection';
import { IonicModule } from '@ionic/angular';
import { ToolbarModalComponent } from '../../../common/components/toolbar-modal/toolbar-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { CurafidaTableComponent } from '../../../table/components/curafida-table/curafida-table.component';
import { QuestionnaireType } from '../../../questionnaire/entities/questionnaire-type';

@Component({
    selector: 'lib-my-medax-questionnaire-submission',
    templateUrl: './my-medax-questionnaire-submission.component.html',
    styleUrls: ['./my-medax-questionnaire-submission.component.scss'],
    imports: [IonicModule, ToolbarModalComponent, TranslateModule, NgIf, CurafidaTableComponent],
    standalone: true,
})
export class MyMedaxQuestionnaireSubmissionComponent implements OnInit {
    readonly isMobile: boolean;
    @Input()
    myMedaxQuestionnaireSubmission: MyMedaxQuestionnaireSubmission;
    @Input()
    allowFillNewVersion: boolean;
    @Input()
    consultationContent?: ConsultationContent;
    @Input()
    orderContent?: OrderContent;
    @Input()
    taskResource?: TaskResource;
    @Input()
    answererFullName: string;
    @Input()
    concernedUserFullName: string;
    questionnaireSubmissionTableConfig: TableConfig<QuestionAndAnswer[]> = new TableConfig();
    dateText: string;
    protected readonly log: Logger;

    constructor(
        private userService: UsersService,
        private myMedaxService: MyMedaxService,
        private consultationService: ConsultationService,
        private orderService: OrderService,
        private loggingService: LoggingService,
        private toastService: ToastService,
        private styleService: StyleService,
        private therapyMyMedaxService: TherapyMyMedaxService,
        private browser: BrowserNavigationService,
        private readonly loadingService: LoadingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
    }

    private _showIcons = true;

    get showIcons() {
        return this._showIcons;
    }

    @Input()
    set showIcons(value: boolean) {
        if (this._showIcons !== value) {
            this._showIcons = value;
            this.initListConfig();
            this.initListEntries();
        }
    }

    static formatStringIfDate(dateString: string): string {
        const myMedaxDateRegex = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
        if (!myMedaxDateRegex.test(dateString)) return dateString;
        return format(parse(dateString, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');
    }

    async ngOnInit(): Promise<void> {
        if (!this.concernedUserFullName) {
            if (!this.myMedaxQuestionnaireSubmission.concernedUser) {
                try {
                    this.concernedUserFullName = await this.userService.getUserFullName(
                        this.myMedaxQuestionnaireSubmission.concernedUsername,
                    );
                } catch (e) {
                    this.concernedUserFullName = this.myMedaxQuestionnaireSubmission.concernedUsername;
                }
            } else {
                this.concernedUserFullName = `${this.myMedaxQuestionnaireSubmission.concernedUser.lastname}, ${this.myMedaxQuestionnaireSubmission.concernedUser.firstname}`;
            }
        }
        if (!this.answererFullName) {
            if (!this.myMedaxQuestionnaireSubmission.answererUser) {
                try {
                    this.answererFullName = await this.userService.getUserFullName(
                        this.myMedaxQuestionnaireSubmission.answererUsername,
                    );
                } catch (e) {
                    this.answererFullName = this.myMedaxQuestionnaireSubmission.answererUsername;
                }
            } else {
                this.answererFullName = `${this.myMedaxQuestionnaireSubmission.answererUser.lastname}, ${this.myMedaxQuestionnaireSubmission.answererUser.firstname}`;
            }
        }
        this.dateText = this.myMedaxQuestionnaireSubmission.created_at
            ? format(parseISO(this.myMedaxQuestionnaireSubmission.created_at), 'dd.MM.yyyy, HH:mm') + ' Uhr'
            : '';
        this.initListConfig();
        this.initListEntries();
    }

    async downloadPdf(): Promise<void> {
        try {
            if (!this.consultationContent && !this.orderContent) {
                await this.myMedaxService.downloadPdf(
                    this.myMedaxQuestionnaireSubmission.concernedUsername,
                    this.myMedaxQuestionnaireSubmission.uuid,
                );
            } else if (this.consultationContent) {
                await this.consultationService.downloadFile(
                    this.consultationContent.consultationUuid,
                    this.consultationContent,
                );
            } else if (this.orderContent) {
                await this.orderService.downloadFile(this.orderContent.orderUuid, this.orderContent);
            }
        } catch (e) {
            this.log.error('Error in downloadPdf', e);
            this.toastService.showToast('Beim Herunterladen des PDFs ist ein Fehler aufgetreten.', IonicColor.danger);
        }
    }

    async createNewVersion(): Promise<void> {
        let fillLink: MyMedaxFillLink;
        const redirection = MyMedaxRedirection.forCurrentLocation().withActivationByCapacitor();
        try {
            this.loadingService.startLoadingModal();
            if (!this.consultationContent && !this.orderContent && !this.taskResource) {
                fillLink = await this.myMedaxService.getFillLink(
                    this.myMedaxQuestionnaireSubmission.concernedUsername,
                    this.myMedaxQuestionnaireSubmission.questionnaire.identifier.value,
                    redirection,
                    true,
                    [this.myMedaxQuestionnaireSubmission.questionnaire.id],
                    this.myMedaxQuestionnaireSubmission.submissionVersioningUuid,
                );
            } else if (this.orderContent) {
                fillLink = await this.orderService.getFillLink(
                    this.orderContent.orderUuid,
                    this.orderContent.orderQuestionnaireUuid,
                    redirection,
                    true,
                    [this.myMedaxQuestionnaireSubmission.questionnaire.id],
                    this.myMedaxQuestionnaireSubmission.submissionVersioningUuid,
                );
            } else if (this.consultationContent) {
                fillLink = await this.consultationService.getFillLink(
                    this.consultationContent.consultationUuid,
                    this.consultationContent.consultationQuestionnaireUuid,
                    redirection,
                    true,
                    [this.myMedaxQuestionnaireSubmission.questionnaire.id],
                    this.myMedaxQuestionnaireSubmission.submissionVersioningUuid,
                );
            } else if (this.taskResource) {
                fillLink = await this.therapyMyMedaxService.getMyMedaxFillLink(
                    this.taskResource.concernedUsername,
                    this.taskResource.id.toString(),
                    redirection,
                    QuestionnaireType.TASK,
                    true,
                    [this.myMedaxQuestionnaireSubmission.questionnaire.id],
                    this.myMedaxQuestionnaireSubmission.submissionVersioningUuid,
                );
            }
            this.browser.openTargetSelf(fillLink.url);
        } catch (e) {
            this.log.error('Error in createNewVersion', e);
            this.toastService.showToast('Beim Öffnen des Formulars ist ein Fehler aufgetreten.', IonicColor.danger);
        } finally {
            this.loadingService.stopLoadingModal();
        }
    }

    private initListConfig() {
        this.questionnaireSubmissionTableConfig.itemSettings = [
            {
                id: 'question',
                prop: 'question',
                header: 'Frage',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '50%',
                columnPosition: 0,
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: 'answer',
                prop: 'answer',
                header: 'Antwort',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 1,
                sortOrderMobile: 1,
            },
        ];
        if (this.showIcons) {
            this.questionnaireSubmissionTableConfig.itemSettings.push({
                id: 'icon',
                prop: 'icons',
                header: '',
                type: ItemType.ADAPTER,
                adapter: IconAdapterComponent,
                width: '10%',
                columnPosition: 2,
                sortOrderMobile: 2,
            });
        }
        this.questionnaireSubmissionTableConfig.isOpenDetailEnable = false;
        this.questionnaireSubmissionTableConfig.emptyListLabel = 'ANY_ITEMS';
    }

    private processQuestion(
        question,
        questionAndAnswers: QuestionAndAnswer[],
        questionPrefix?: string,
    ): QuestionAndAnswer[] {
        const questionAndAnswer = new QuestionAndAnswer();
        questionAndAnswer.question = question?.text?.replace('\n', '');
        if (question.answer) {
            if (questionPrefix) questionAndAnswer.question = `${questionPrefix}${questionAndAnswer.question}`;
            questionAndAnswer.answer = '';
            questionAndAnswer.icons = [];
            for (const answer of question.answer) {
                const value = MyMedaxQuestionnaireSubmissionComponent.formatStringIfDate(this.processAnswer(answer));
                questionAndAnswer.answer = `${questionAndAnswer.answer}${value}\n`;
                if (this.showIcons) {
                    const icon_source = this.processIcon(answer);
                    if (icon_source !== '-') {
                        questionAndAnswer.icons.push(
                            this.taskResource.icons.find(
                                (icon) =>
                                    icon.source.split(new RegExp('idef[0-9]*'), 2)[1] ===
                                    icon_source.split(new RegExp('idef[0-9]*'), 2)[1],
                            ),
                        );
                    }
                }
            }
            questionAndAnswers.push(questionAndAnswer);
        } else if (question.item) {
            for (const innerQuestion of question.item) {
                this.processQuestion(
                    innerQuestion,
                    questionAndAnswers,
                    questionAndAnswer.question ? `${questionAndAnswer.question}: ` : undefined,
                );
            }
        }
        return questionAndAnswers;
    }

    private processIcon(answer): string {
        let result = '-';
        for (const key in answer) {
            if (['linkId'].includes(key)) {
                result = answer[key].toString();
            } else if (key.includes('answer') || key.includes('item')) {
                result = this.processIcon(answer[key][0]);
            }
        }
        return result;
    }

    private processAnswer(answer): string {
        let result = '-';
        for (const key in answer) {
            // So far we have only had cases of 'valueString' and 'valueDecimal'
            // For new value types that do not work as strings this might have to be adjusted
            if (['valueString', 'valueDecimal', 'valueDate'].includes(key)) {
                result = answer[key].toString();
            } else if (key.includes('answer') || key.includes('item')) {
                result = this.processAnswer(answer[key][0]);
            }
        }
        return result;
    }

    private initListEntries() {
        const questionAndAnswerList = [];
        if (this.myMedaxQuestionnaireSubmission.questionnaire.item) {
            for (const question of this.myMedaxQuestionnaireSubmission.questionnaire.item) {
                this.processQuestion(question, questionAndAnswerList);
            }
        }
        if (this.myMedaxQuestionnaireSubmission.scoring) {
            for (const key in this.myMedaxQuestionnaireSubmission.scoring) {
                const questionAndAnswer = new QuestionAndAnswer();
                questionAndAnswer.question = key + ` Score`;
                questionAndAnswer.answer = this.myMedaxQuestionnaireSubmission.scoring[key];
                questionAndAnswerList.push(questionAndAnswer);
            }
        }
        this.questionnaireSubmissionTableConfig.list = PaginatedResponse.init(questionAndAnswerList);
    }
}
