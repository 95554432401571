import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { CurafidaInputComponent } from '../curafida-input';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'curafida-open-modal-input',
    templateUrl: './curafida-open-modal-input.component.html',
    styleUrls: ['./curafida-open-modal-input.component.scss'],
})
export class CurafidaOpenModalInputComponent extends CurafidaInputComponent {
    @Output()
    openModalEvent: EventEmitter<void> = new EventEmitter<void>();
    protected readonly log: Logger;
    @Input()
    buttonLabel: string;
    @Input()
    isButtonHidden: boolean;

    constructor(
        private loggingService: LoggingService,
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    openModal(): void {
        this.openModalEvent.emit();
    }
}
