import { FormControl, FormGroup } from '@angular/forms';
import { FileItem } from 'ng2-file-upload';

export class CurafidaContentInputForm {
    readonly group: FormGroup;

    get isDisabled(): boolean {
        return this.group.disabled;
    }

    set isDisabled(value: boolean) {
        if (value) {
            this.group.disable();
        } else {
            this.group.enable();
        }
    }

    get files(): FileItem[] {
        return [...this.group.controls.files.value];
    }

    private get _files(): FileItem[] {
        return this.group.controls.files.value;
    }

    constructor() {
        this.group = new FormGroup({
            files: new FormControl({ value: [], disabled: false }),
        });
    }

    push(fileItem: FileItem): void {
        if (!this._files.find((it) => it.file.name === fileItem.file.name)) {
            this._files.push(fileItem);
            this.group.markAsDirty();
        }
    }

    remove(fileItem: FileItem): void {
        this._files.splice(this._files.indexOf(fileItem), 1);
    }

    toPayload(): FormData[] {
        return this._files.map((it) => {
            const uploadFormData = new FormData();
            uploadFormData.append('file', it.file.rawFile as File);
            return uploadFormData;
        });
    }

    clear(): void {
        this.group.controls.files.setValue([]);
    }
}
