// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-adapter-mobile-width {
  width: 90%;
}

.timeline-badge {
  top: 8px !important;
}

.timeline {
  list-style: none;
  padding: 1px 0 1px;
  position: relative;
}

.timeline:before {
  content: "";
}

.timeline > li {
  position: relative;
}

.timeline > li:before {
  height: 100%;
  transform: translateY(50%);
}

.timeline > li:last-child:before {
  content: none;
}

.timeline:before {
  top: 10px !important;
  bottom: 50px !important;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: var(--ion-color-primary);
  margin-left: 1.5px;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-actionline/curafida-action-line.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGA;EACI,YAAA;EACA,0BAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAGA;EACI,oBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,0CAAA;EACA,kBAAA;AAAJ","sourcesContent":[".timeline-adapter-mobile-width {\n    width: 90%;\n}\n\n.timeline-badge {\n    top: 8px !important;\n}\n\n.timeline {\n    list-style: none;\n    padding: 1px 0 1px;\n    position: relative;\n}\n\n.timeline:before {\n    content: '';\n}\n\n.timeline > li {\n    position: relative;\n}\n\n\n.timeline > li:before {\n    height: 100%;\n    transform: translateY(50%);\n}\n\n.timeline > li:last-child:before {\n    content: none;\n}\n\n.timeline:before {\n    top: 10px !important;\n    bottom: 50px !important;\n    position: absolute;\n    content: \" \";\n    width: 2px;\n    background-color: var(--ion-color-primary);\n    margin-left: 1.5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
